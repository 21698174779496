import { ref, watch, computed } from "@vue/composition-api";

// Notification
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import instance from "@/libs/axios";
import { useUtils as useI18nUtils } from "@core/libs/i18n";
import { useToast } from "vue-toastification/composition";

export default function useOrderInPreTrackingList() {
  // Use toast
  const { t } = useI18nUtils();
  const toast = useToast();

  const refOrderListTable = ref(null);

  // Table Handlers
  const tableColumns = [
    { key: "photo", label: t("photo") },
    /*{ key: "order.ref", label: t("Ref") },*/
    { key: "order.client.name", label: t("client") },
    { key: "product_combination.product_shortcut", label: t("Product") },
	/*{ key: "preparations.name", label: t("Preparation") },*/
    { key: "ordered_qty", label: t("Ordered quantity") },
    /*{ key: "piece", label: t(" ordered Piece") },*/
    { key: "shipping_type.name", label: t("Shipping type") },
    /*{ key: "shipping_date", label: t("shipping date") },*/
    /*{ key: "emergency_degree.name", label: t("emergency degree") },*/
    { key: "actions", label: t("Actions") },
  ];
  const perPage = ref(10);
  const totalOrders = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const refValue = ref("");
  const subProfileValue = ref(null);
  const clientValue = ref(null);
  const shippingDateValue = ref(null);
  const emergencyDegreeValue = ref(null);
  const sortBy = ref("id");
  const isSortDirDesc = ref(true);
  const timeoutId = ref(null);

  const dataMeta = computed(() => {
    const localItemsCount = refOrderListTable.value
      ? refOrderListTable.value.localItems.length
      : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalOrders.value,
    };
  });

  const refetchData = () => {
    refOrderListTable.value.refresh();
  };

  watch(
    [
      currentPage,
      perPage,
      refValue,
      subProfileValue,
      clientValue,
      shippingDateValue,
      emergencyDegreeValue,
    ],
    () => {
      if (refValue.value) {
        clearTimeout(timeoutId.value);
        timeoutId.value = setTimeout(async () => {
          refetchData();
        }, 600);
      } else {
        refetchData();
      }
    }
  );

  const fetchOrders = (ctx, callback) => {
    instance
      .get("/orders/order-lines/storekeeper/", {
        params: {
          state: "in_preparation",
          page: currentPage.value,
        },
      })
      .then((response) => {
        const { count, results } = response.data;
        totalOrders.value = count;
        callback(results);
      })
      .catch((err) => {
        const error = err.response
          ? Object.values(err.response.data)[0][0]
          : err.message;

        toast({
          component: ToastificationContent,
          props: {
            title: t("Error"),
            text: error ?? t("An error has occurred. Please try again"),
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };

  return {
    fetchOrders,
    tableColumns,
    perPage,
    currentPage,
    totalOrders,
    dataMeta,
    perPageOptions,
    refValue,
    sortBy,
    isSortDirDesc,
    refOrderListTable,
    refetchData,
    t,
    subProfileValue,
    clientValue,
    shippingDateValue,
    emergencyDegreeValue,
  };
}
